<template>
  <div class="challenge">
    <h1>The Challenge</h1>
    <h2>Step One</h2>
    <p>Create a public API that can recieve JSON payloads.</p>
    <code>{ "user_id": int, "message": string }</code>
    
    <h2>Step Two</h2>
    <p>Your app will recieve many code_id:snippet pairs. Your mission is to find an answer to the challenge by taking all the snippets for a given code_id, sorting them alphabetically, and then generating a keyed hash of the value.</p>

    <h2>Step Three</h2>
    <p>Send the answer back to our API in a JSON payload. The faster you return the answer, the more points you score. If you return all the codes, you'll advance to the next wave of the challenge and receive more snippets. You have 10 minutes to return these answers, starting when the last payload is sent to your API. If you do not, your run will end and your total score will be posted on the scoreboard.</p>

    <h1>The Hashing</h1>
    <p>This process is a little complicated, so we're going to break it down into specific steps.</p>
    <ol>
      <li>Take all the snippets for a given code_id.
        <pre><code>[{ "code_id": "qwerty", "snippet": "abcdef" }, { "code_id": "qwerty", "snippet": "zyxwvu" }]</code></pre>
      </li>
      
      <li>
        Concatenate the snippets together. <br>
        <pre><code>"abcdefzyxwvu"</code></pre>
      </li>
      <li>
        Sort the snippets alphabetically.
        <pre><code>"abcdefzyxwvu" => "abcdefuvwyxz"</code></pre>
      </li>
      <li>Generate a keyed hash using SHA256 and your api key together using your language of choice It will look something like this pseudocode:
        <code>hmac("your_api_key", "abcdefuvwyxz", sha256).hexdigest()</code>
      </li>
      <li>
        Send the all caps answer back to our API in a JSON payload. You must also include your secret key in the "x-api-key" header of your request.
        <pre><code>{ "code_id": "qwerty", "answer": "09E5F0AF52E8A1C61804E7F7DE429E79E9B453FA53C504ED32A4CB4E19DD5BE6" }</code></pre>
      </li>
    </ol>

    <p>An HTTP 200 response means the answer is correct. Any other response means something isn't right.</p>

    <p>Some areas of this guide are intentionally vague. That is part of the challenge. Happy building!</p>

  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Challenge',
  components: {
  },
  computed: {
    waveData: function() {
      return {
        "wave_one": {
          "id": 1,
          "title": "Wave One",
          "message": "A simple start, just match the snippets in one payload and return them.",
          "snippets": 64,
          "payloads": 1
        },
        "wave_two": {
          "id": 2,
          "title": "Wave Two",
          "message": "Match the snippets between two payloads and return them.",
          "snippets": 128,
          "payloads": 2
        },      
      };
    }
  }
}
</script>


<style scoped>
li {
  list-style: outside;
  margin-bottom: 0.4em;
}
h2 {
  margin: 1em 0 0;
}
a {
  color: #42b983;
}

code {
  display: block;
  font-family: monospace;
  background-color: lightgray;
  color: black;
  border-radius: 3px;
  font-size: 1.3em;
  margin: 0.5em 0;
  padding: 0.5em 0.2em;
}
</style>
